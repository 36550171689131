.blurDiv,
.blurDivHide {
  position: fixed;
  z-index: 1000;
  left: 0px;
  top: 0px;
  display: grid;
  grid-auto-rows: calc(100% - 140px) 110px;
  align-items: center;
  background: rgba(12, 12, 12, 0.6);
  backdrop-filter: blur(7px);
  height: 100%;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 50px;
  grid-row-gap: 30px;
  animation-name: appear;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  box-sizing: border-box;
  /* padding: 40px 100px; */
}

.blurDivHide {
  animation-name: hide;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.close > img {
  width: 30px;
  height: 30px;
}

.photoList {
  display: flex;
  flex-direction: row;
  height: 110px;
  max-width: 25%;
  overflow-x: auto;
  overflow-y: hidden;
  justify-self: center;
  scrollbar-width: none;
}

.photoList::-webkit-scrollbar {
  display: none;
}

.photoList > .photo {
  max-width: 55px;
  min-width: 55px;
  height: 110px;
  border-radius: 10px;
  object-fit: cover;
  margin-left: 5px;
  cursor: pointer;
}

.photoList:first-child {
  margin-left: 0px;
}

.photoList > .photoSelected {
  height: 110px;
  width: 110px;
  min-width: 110px;
  border-radius: 10px;
  object-fit: cover;
  margin-left: 5px;
}

.photoShown {
  max-width: 90vw;
  max-height: 100%;
  height: 100%;
  position: relative;
  /* background-color: antiquewhite; */
  margin-right: auto;
  margin-left: auto;
}

.photoShown > img {
  height: 100%;
  max-width: 90vw;
  object-fit: contain;
  border-radius: 20px;
}

.next {
  z-index: 2;
  border-radius: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  width: 30%;
  height: 100%;
}

.prev {
  z-index: 2;
  border-radius: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
  width: 30%;
  height: 100%;
}

@media (max-width: 690px) {
  .photoShown > img {
    max-width: 100vw;
    border-radius: 0;
    /* height: auto; */
  }

  .photoShown {
    max-width: 100vw;
    /* height: fit-content; */
  }

  .photoList {
    max-width: 85%;
  }

  .close > img {
    width: 20px;
    height: 20px;
  }

  .blurDiv,
  .blurDivHide {
    padding-top: 50px;
  }
}
