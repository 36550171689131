.blurDiv{
    position: fixed;
    z-index: 1000;
    left: 0px;
    top: 0px;
    display: grid;
    grid-auto-rows: calc(100% - 140px) 110px;
    align-items: center;
    background: rgba(12, 12, 12, 0.6);
    backdrop-filter: blur(7px);
    height: calc(100% - 85px);
    width: 100%;
    padding-top: 35px;
    padding-bottom: 50px;
    grid-row-gap: 30px;
}

.photoList{
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    height: 110px;
    max-width: 25%;
    overflow-x: auto;
    overflow-y: hidden;
}

.photoList > .photo{
    max-width: 55px;
    min-width: 55px;
    height: 110px;
    border-radius: 10px;
    object-fit: cover;
    margin-left: 5px;
    cursor: pointer;
}

.photoList:first-child{
    margin-left: 0px;
}

.photoList > .photoSelected{
    height: 110px;
    width: 110px;
    border-radius: 10px;
    object-fit: cover;
    margin-left: 5px;
}

.photoShown{
    max-width: 90vw;
    max-height: 100%;
    height: 100%;
    position: relative;
    /* background-color: antiquewhite; */
    margin-right: auto;
    margin-left: auto;
}

.photoShown > .img{
    height: 100%;
    max-width: 90vw;
    object-fit: contain;
    border-radius: 20px;
}

.close{
    /* width: 58.34px !important;
    height: 58.34px !important; */
    position: absolute;
    right: -1px;
    top: -1px;
    cursor: pointer;
    z-index: 3;
}

.next{
    z-index: 2;
    border-radius: 20px;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    width: 30%;
    height: 100%;
}

.prev{
    z-index: 2;
    border-radius: 20px;
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
    width: 30%;
    height: 100%;
}