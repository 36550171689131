.sliderContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 50px;
}

.slides {
  width: 100%;
  aspect-ratio: 3/4;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 3/4;
  /* position: relative; */
  border-radius: 40px;
  /* overflow: hidden; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 105% 105%;
  z-index: 5;
}

.hidden {
  opacity: 0;
  animation-name: appear;
  animation-timing-function: linear;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 0;
}

@keyframes appear {
  0% {
    opacity: 0;
    background-size: 100% 100%;
  }
  100% {
    opacity: 1;
    background-size: 105% 105%;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    background-size: 105% 105%;
  }
  100% {
    opacity: 0;
    background-size: 110% 110%;
  }
}

.hideAnimation {
  animation-name: disappear;
  animation-timing-function: linear;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.info {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 18px 0;
  background: linear-gradient(360deg, #0b0b0b 29.69%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.9;
  backdrop-filter: blur(1.40449px);
  font-weight: 450;
  font-size: 12.5px;
  line-height: 15px;
  color: #e0e0e0;
  border-radius: 0 0 40px 40px;
}

.next,
.prev {
  height: 22px;
  width: auto;
  margin: 0 0 0 18px;
}

.prev {
  transform: rotate(180deg);
  margin: 0 18px 0 0;
}

.nextSection {
  z-index: 10;
  width: 50%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.prevSection {
  z-index: 10;
  width: 50%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}
