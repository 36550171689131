.mainDiv {
  background-color: white;
}

.head {
  margin-top: 115px;
  width: 100vw;
  height: 540px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.headInfo {
  width: 730px;
  display: flex;
  flex-direction: column;
}

.headInfo > .name {
  color: white;
  font-size: 75px;
  font-family: "Kyiv";
  font-weight: 700;
  line-height: 90px;
  margin-bottom: 20px;
}

.headInfo > .line {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 20px;
  margin-bottom: 20px;
}

.content {
  width: 730px;
  color: #171717;
}

.content > p {
  font-weight: 500;
  line-height: 28px;
  font-size: 20px;
  margin-bottom: 0 0 38px;
}

.content > h1 {
  margin-top: 68px;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 34px;
}

.content > figure{
    margin: 0px 0px 40px;
}

.content > figure > img {
  width: 730px !important;
  height: auto;
}

.content > figure > figcaption {
    align-self: center;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #565656;
    font-weight: 450;
}
