.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainDiv {
  background-image: url("../../res/img/teamBack.webp");
  background-size: 100%;
}

.title {
  margin-top: 230px;
  font-weight: 500;
  font-family: "Kyiv";
  color: white;
  font-size: 50px;
  line-height: 70px;
  margin-bottom: 120px;
}

.input {
  outline: none;
  margin-bottom: 30px;
  border: 1.5px solid #b8b8b8;
  padding: 15px 39px 16px;
  border-radius: 30px;
  backdrop-filter: blur(3.08166px);
  background: rgba(13, 1, 2, 0.5);
  font-size: 20px;
  color: #b8b8b8;
  font-weight: 450;
  width: 650px;
  box-sizing: border-box;
  font-family: "KyivSans";
  resize: none;
}

.addText {
  margin-top: 30px;
  color: #b8b8b8;
  font-size: 20px;
  font-weight: 450;
  margin-bottom: 15px;
  margin-left: 5px;
}

.photoList {
  display: grid;
  grid-template-columns: repeat(3, 230px);
}

.photoUpload {
  width: 200px;
  height: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 30px;
  border: 1.5px solid #b8b8b8;
  margin-bottom: 30px;
}

.photoUpload > img {
  margin-bottom: 4px;
}

.photoUpload > input {
  display: none;
}

.photoPreview {
  width: 200px;
  height: 140px;
  border-radius: 30px;
  border: 1.5px solid #b8b8b8;
  margin-bottom: 30px;
  position: relative;
}

.photoPreview > img {
  object-fit: cover;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

.remove {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(6, 6, 6, 0.8);
  backdrop-filter: blur(25px);
  border-radius: inherit;
  z-index: 2;
  cursor: pointer;
}

.send,
.sendDisabled {
  margin-top: 60px;
  padding: 16px 55px 22px;
  border-radius: 30px;
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 125px;
  width: fit-content;
  box-sizing: border-box;
}

.sendDisabled {
  background: rgba(70, 70, 70, 1);
}

.send {
  cursor: pointer;
  background: #bd0000;
}

.send:active {
  background: #790000;
}

@media (max-width: 1100px) {
  .mainDiv {
    background-image: url("../../res/img/smallTeamBack.webp");
    background-size: 100%;
  }
}

@media (max-width: 690px) {
  .title {
    font-size: 25px;
    line-height: 30px;
    margin-top: 105px;
    margin-bottom: 54px;
    /* margin-left: 40px; */
  }

  .input {
    margin-bottom: 23px;
    width: 100%;
    max-width: 300px;
    font-size: 14px;
    border-radius: 20px;
    padding: 11.5px 15.5px 13.5px;
    border-width: 1px;
  }

  .addText {
    margin-top: 8px;
    font-size: 14px;
    margin-bottom: 13px;
  }

  .photoList {
    grid-template-columns: repeat(3, 77px);
    grid-column-gap: 15px;
  }

  .photoUpload {
    border-width: 1px;
    width: 77px;
    height: 50px;
    border-width: 1px;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .photoUpload > img {
    width: 27.25px;
    height: 26.94px;
  }

  .photoPreview {
    width: 77px;
    height: 50px;
    margin-bottom: 15px;
    border-radius: 20px;
    border-width: 1px;
  }

  .send,
  .sendDisabled {
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin-top: 15px;
    margin-bottom: 60px;
    font-size: 16px;
    padding: 12px 0 17px;
  }
}
