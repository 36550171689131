.blur,
.blurHide {
  position: fixed;
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
  z-index: 200;
  animation-name: show;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  box-sizing: border-box;
  /* padding: 20px 0px; */
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blurHide {
  animation-name: hide;
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-height: 90%;
  overflow-y: auto;
  width: 490px;
  scrollbar-width: 0;
}

.content::-webkit-scrollbar {
  display: none;
}

.headerLine {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 75px;
}

.title {
  width: 400px;
  font-size: 30px;
  line-height: 42px;
  color: white;
  font-weight: 750;
  font-family: "KyivSans";
}

.title > span {
  color: #db0000;
}

.close {
  margin-top: 20px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  background-image: url("../../res/img/closeTimer.svg");
  background-size: 100% 100%;
}

/* Variant 1 */

.animation {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-bottom: 80px;
}

.animation > .imgMap {
  width: inherit;
  height: auto;
}

.church,
.churchDestroyed {
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  bottom: 150px;
  width: 60px;
  height: auto;
}

.explosion {
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  bottom: 175px;
  width: 100px;
  height: auto;
}

.mesile {
  position: absolute;
  left: 65%;
  /* transform: translateX(-50%); */
  bottom: 250px;
  width: 55px;
  height: auto;
}

/* Variant 2 */

.animation2 {
  width: 200px;
  height: auto;
  margin-bottom: 35px;
  position: relative;
  align-self: flex-end;
  /* margin-top: 75px; */
}

.sheva {
  height: auto;
  width: 200px;
  animation-name: shevaShow;
  animation-timing-function: ease-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 0.4s;
  opacity: 0;
}

.dialog {
  position: absolute;
  top: -40px;
  right: 140px;
  width: 275px;
  height: 170px;
  background-image: url("../../res/img/animation/dialog.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  animation-name: shevaShow;
  animation-timing-function: ease-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 1s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 5px;
}

.dialog > div {
  font-weight: 450;
  font-size: 18px;
  line-height: 25px;
  color: #e6e6e6;
  transform: rotate(-12deg);
  animation-name: shevaShow;
  animation-timing-function: ease-out;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 1.6s;
  width: 200px;
  opacity: 0;
}

@keyframes shevaShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.content2 {
  animation-name: shevaShow;
  animation-timing-function: ease-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 2.4s;
  opacity: 0;
}

/*  */

.text {
  font-weight: 450;
  font-size: 20px;
  color: #c4c4c4;
  margin-bottom: 45px;
  font-family: "KyivSans";
}

.moneyLine {
  width: 100%;
  display: grid;
  grid-column-gap: 17px;
  grid-template-columns: repeat(4, 1fr);
}

.liqLink {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px 0 15px;
  color: #bfbfbf;
  font-size: 20px;
  font-weight: 450;
  border: 2px solid #c4c4c4;
  border-radius: 999px;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.25s linear;
}

.liqLink:hover {
  color: white;
  border-color: white;
}

@media (max-width: 690px) {
  .content {
    width: calc(100% - 40px);
    max-width: 400px;
  }

  .headerLine {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 30px;
  }

  .title {
    width: 100%;
    font-size: 25px;
    line-height: 35px;
    max-width: 280px;
    align-self: flex-start;
    margin-top: 15px;
  }

  .animation {
    width: 100%;
    max-width: 280px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .church,
  .churchDestroyed {
    bottom: 75px;
    width: 30px;
  }

  .explosion {
    bottom: 90px;
    width: 52px;
  }

  .mesile {
    bottom: 125px;
    width: 27px;
  }

  .text {
    font-size: 12px;
    margin-bottom: 25px;
  }

  .moneyLine {
    width: 100%;
    max-width: 400px;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }

  .liqLink {
    padding: 8px 0 10px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .other {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .liqLink:hover {
    color: #bfbfbf;
    border-color: #c4c4c4;
  }

  .liqLink:active {
    color: white;
    border-color: white;
  }

  .animation2 {
    width: 105px;
    margin-bottom: 15px;
    margin-top: 40px;
    margin-right: 50px;
  }
  
  .sheva {
    width: 105px;
  }

  .dialog {
    position: absolute;
    top: -30px;
    right: 80px;
    width: 176px;
    height: 106px;
  }
  
  .dialog > div {
    font-size: 11.5px;
    line-height: 16px;
    width: 70%;
  }
}
