.sliderContainer {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  position: relative;
  margin-top: 100px;
}

.slider {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  scrollbar-width: none;
}

.slider::-webkit-scrollbar {
  display: none;
}

.nextArrow,
.prevArrow {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-out;
  width: 220px;
  min-width: 220px;
  height: 100%;
  opacity: 1;
  z-index: 2;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 25px;
}

.nextArrow {
  right: 8px;
  border-radius: 0px 57px 57px 0px;
  /* background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
  align-items: flex-end;
}

.prevArrow {
  /* background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
  left: 8px;
  border-radius: 57px 0 0 57px;
  align-items: flex-start;
}

.prevArrow > img {
  transform: rotate(180deg);
  transform-origin: center;
}

.hidden {
  opacity: 0;
}

.slide {
  display: flex;
  flex-direction: row;
  width: 100%;
  transform: translateX(-100%);
}

.nextSlide {
  animation-name: next;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 1.2s;
}

.prevSlide {
  animation-name: prev;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-duration: 1.2s;
}

@keyframes next {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes prev {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.card {
  width: 320px;
  min-width: 320px;
  height: 425px;
  margin: 0 16px;
  scroll-margin: 0 16px;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  position: relative;
  border-radius: 55px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.name {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 135px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 20px 20px;
  background: linear-gradient(360deg, #0b0b0b 29.69%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.9;
  backdrop-filter: blur(2px);
  border-radius: 0 0 55px 55px;
}
