.footer {
  width: 100vw;
  z-index: 100;
  /* position: absolute; */
  /* bottom: 0px;
    left: 0px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer > hr {
  border: 1px solid #5f5f5f;
  width: 90%;
  min-width: 1070px;
}

.line{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.logoBlock{
  display: flex;
  flex-direction: row;
}

.logoBlock > img{
  margin-right: 30px;
  height: 20px;
  width: auto;
}

.policyBlock{
  display: flex;
  flex-direction: column;
  text-align: right;
}

.policyBlock > a{
  font-weight: 450;
  font-size: 15px;
  color: rgba(159, 159, 159, 1) !important;
  text-decoration: none !important;
  margin-top: 5px;
}

.footerInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 50px 5px 0px;
  font-family: "KyivSans";
  font-weight: 450;
  font-size: 20px;
  color: #9f9f9f;
}

.textBlock {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.text {
  /* padding-bottom: 5px; */
  text-decoration: none;
  color: #9f9f9f;
  margin-bottom: 20px;
}

.fundInfo {
  display: flex;
  flex-direction: column;
  font-weight: 450;
  color: #9f9f9f;
  font-size: 15px;
}

.linkBlock {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
}

.contactLine {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contactLine > .imgLink {
  margin-left: 30px;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .footer > hr {
    min-width: 670px;
  }
}

@media (max-width: 690px) {
  .footer > hr {
    width: calc(100% - 40px);
    min-width: 10px;
  }

  .footerInfo {
    flex-direction: column;
    font-size: 14px;
    margin: 29px 5px 15px;
  }

  .contactLine {
    margin-top: 20px;
    width: 100%;
    /* justify-content: space-between; */
  }

  .contactLine > .imgLink {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    /* justify-content: space-between; */
  }

  .fundInfo{
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .text {
    margin-bottom: 10px;
  }

  .city{
    margin-left: 37px;
  }

  .line{
    flex-direction: column-reverse;
    justify-content: center;
  }

  .logoBlock{
    margin-top: 15px;
  }

  .logoBlock > img{
    margin-right: 15px;
    margin-left: 15px;
  }

  .policyBlock > a{
    text-align: center;
  }
}
