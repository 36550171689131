/* Description style */

.container {
  position: absolute;
  z-index: 100;
  top: 35px;
  right: 35px;
  width: 360px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
  overflow: hidden;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.fullDescription {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
  border-radius: 32px;
  background: rgba(37, 37, 37, 1);
  position: relative;
  scrollbar-width: none;
}

.fullDescription::-webkit-scrollbar{
  display: none;
}

.fullDescription > .imgDiv {
  min-height: 300px;
  max-height: 300px;
  width: 360px;
  border-radius: 32px 32px 0px 0px;
  position: relative;
}

.imgDiv > .photo {
  min-height: 300px;
  max-height: 300px;
  width: 360px;
  border-radius: 32px 32px 0px 0px;
  object-fit: cover;
}

.fullDescription > .openPhoto {
  min-height: 300px;
  max-height: 300px;
  width: 360px;
  border-radius: 32px 31px 0px 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  cursor: pointer;
}

.fullDescription > .openPhoto > img {
  width: 44px;
  height: 42px;
}

.fullDescription > .back {
  position: absolute;
  top: 0px;
  left: -1px;
  width: 57px;
  height: 51.04px;
  cursor: pointer;
  z-index: 2;
}

.fullDescription > .content {
  padding-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.fullDescription > .content::-webkit-scrollbar{
  display: none;
}

.fullDescription > .content > .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  margin-bottom: 10px;
  font-style: normal;
  font-family: "KyivSans";
}

.address {
  color: rgba(173, 173, 173, 1);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  font-family: "KyivSans";
}

hr {
  border: 1px solid #373737;
}

.fullDescription > .content > .stats {
  display: flex;
  flex-direction: column;
  padding-top: 11px;
  padding-bottom: 11px;
}

.fullDescription > .content > .stats > .line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  margin-top: 7px;
  font-size: 16px;
  font-family: "KyivSans";
  font-weight: 500;
}

.statName {
  font-weight: 400;
}

.deaths {
  color: #f72020;
}

.info {
  margin-top: 20px;
  margin-bottom: 38px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  font-family: "KyivSans";
}

.donateInfo {
  text-decoration: none;
  background: transparent;
  border-radius: 28.5px;
  width: 321px;
  min-height: 47px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a0c261;
  font-weight: 600;
  font-family: "KyivSans";
  font-style: bold;
  font-size: 25px;
  height: 52px;
  padding-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  border: 2px solid #9abe57;
  box-shadow: 0px 0px 15px 0px #9abe57;
}

.donateInfo:hover {
  background: #7fa23f;
  color: white;
  border-color: #7fa23f;
}

.donateInfo:active {
  background: #627d30;
  color: white;
  border-color: #627d30;
}

/* List styles */

.search {
  min-height: 45px;
  max-height: 45px;
  padding-bottom: 2px;
  color: #515151;
  background: rgba(37, 37, 37, 1);
  border-radius: 32px;
  padding: 0 37px;
  font-size: 16px;
  padding-bottom: 2px;
  font-weight: 400;
  outline: none;
  border: none;
  margin-bottom: 13px;
  font-family: "KyivSans";
}

.filters {
  width: 360px;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: scroll;
  min-height: 38px;
  max-height: 38px;
  margin-bottom: 13px;
  scrollbar-width: none;
}

.filters::-webkit-scrollbar {
  /* color: transparent; */
  display: none;
}

.filterItem,
.filterItemSelected {
  margin-left: 11px;
  font-size: 15px;
  border-radius: 20px;
  border: 3px solid #252525;
  padding: 5px 15px 8px 15px;
  font-family: "KyivSans";
  color: white;
  height: fit-content;
  white-space: nowrap;
  cursor: pointer;
  /* max-height: fit-content; */
}

.filterItem {
  font-weight: 400;
  background: #121212;
}

.filterItemSelected {
  font-weight: 500;
  background: #252525;
}

.filterItem:first-child {
  margin-left: 0px;
}

.filterItemSelected:first-child {
  margin-left: 0px;
}

.search:focus {
  color: rgb(223, 223, 223);
}

.list {
  display: flex;
  flex-direction: column;
  background: #252525;
  border-radius: 32px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 13px 13px 0 13px;
  scrollbar-width: none;
}

.list::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 27px;
  cursor: pointer;
  width: 334px;
}

.item > .itemInfo {
  z-index: 5;
  border-radius: 22px;
  padding: 13px 31px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 272px;
  max-width: 272px;
  box-shadow: 2px 6px 10px 1px rgba(0, 0, 0, 0.35);
  background: #171717;
  /* transform: translateY(-22px); */
  margin-top: -22px;
  /* position: absolute;
  top: 100%;
  left: 0px; */
}

.item > .itemInfo > .name {
  font-weight: 700;
  font-size: 18px;
  color: white;
  width: 100%;
  font-family: "KyivSans";
  font-style: normal;
}

.item > .itemInfo > .address {
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #adadad;
  margin-bottom: 21px;
  width: 100%;
  font-family: "KyivSans";
}

.item > .itemInfo > .statsList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.item > .itemInfo > .statsList > .stat {
  display: flex;
  flex-direction: row;
  color: white;
  font-family: "KyivSans";
  font-weight: 500;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
}

.item > .itemInfo > .statsList > .stat > div {
  margin-left: 14px;
}

.item > img {
  height: 155px;
  width: 333px;
  border-radius: 22px 22px 0px 0px;
  object-fit: cover;
  /* object-position: center center; */
}

.item > .openItem {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 3;
  height: 155px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 50px;
  border-radius: 22px 22px 0px 0px;
  background: rgba(23, 23, 23, 0.8);
  backdrop-filter: blur(2px);
  color: white;
  text-shadow: 0px 1px 4px rgba(206, 206, 206, 0.3);
  font-weight: 700;
  font-size: 25px;
  text-align: center;
}

@media (max-width: 1100px) {
  .container {
    width: 280px;
    top: 20px;
    right: 20px;
    max-height: calc(100vh - 40px);
  }

  .fullDescription {
    max-height: calc(100vh - 40px);
    border-radius: 15px;
  }

  .fullDescription > .openPhoto {
    min-height: 160px;
    max-height: 160px;
    width: 280px;
    border-radius: 15px 14px 0px 0px;
  }

  .fullDescription > .back {
    position: absolute;
    top: 0px;
    left: -1px;
    width: auto;
    height: auto;
    cursor: pointer;
    z-index: 2;
  }

  .fullDescription > .imgDiv {
    min-height: 160px;
    max-height: 160px;
    width: 280px;
    border-radius: 15px 15px 0px 0px;
  }

  .fullDescription > .content > .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .imgDiv > .back {
    width: 40px;
    height: 40px;
    top: -1px;
  }

  .fullDescription > .content > .stats > .line {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
  }

  .fullDescription > .content {
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }

  .info {
    font-size: 16px;
    line-height: 21px;
  }

  .donateInfo {
    border-radius: 28.5px;
    width: 260px;
    min-height: 40px;
    box-sizing: border-box;
    font-size: 16px;
    height: 40px;
    padding-bottom: 4px;
  }

  .search {
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    min-height: 40px;
    padding-bottom: 3px;
    margin-bottom: 10px;
    border-radius: 25px;
  }

  .filters {
    width: 300px;
    margin-bottom: 10px;
    min-height: 33px;
    max-height: 33px;
  }

  .filters > .filterItem {
    font-size: 14px;
    border-width: 1.5px;
  }

  .list {
    display: flex;
    flex-direction: column;
    background: #252525;
    border-radius: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 10px 0 10px;
  }

  .item {
    width: 260px;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .item > img {
    width: 260px;
    height: 150px;
    border-radius: 10px 10px 0px 0px;
    object-position: 0px -10px;
  }

  .item > .itemInfo {
    box-sizing: border-box;
    min-width: 260px;
    max-width: 260px;
    padding: 10px;
    padding-bottom: 15px;
    border-radius: 10px;
  }

  .item > .itemInfo > .name {
    font-weight: 700;
    font-size: 16px;
    color: white;
    width: 100%;
    font-family: "KyivSans";
    font-style: normal;
  }

  .item > .itemInfo > .address {
    margin-bottom: 11px;
  }

  .item > .itemInfo > .statsList > .stat {
    display: flex;
    flex-direction: row;
    color: white;
    font-family: "KyivSans";
    font-weight: 500;
    font-size: 12px;
    /* font-weight: 600; */
    align-items: center;
  }

  .item > .itemInfo > .statsList > .stat > div {
    margin-left: 12px;
  }

  .item > .openItem {
    font-size: 22px;
    border-radius: 10px 10px 0 0;
  }

  .imgDiv > .photo {
    min-height: 160px;
    max-height: 160px;
    width: 280px;
    border-radius: 15px 15px 0px 0px;
    object-fit: cover;
  }
}

@media (max-width: 690px) {
  .container,
  .containerHide {
    width: calc(100vw - 40px);
    position: fixed;
    /* max-height: -webkit-calc(100vh - 80px); */
    max-width: 400px;
    /* top: 80px; */
    bottom: 0px;
    top: auto;
    left: auto;
    margin-left: auto;
    margin-right: auto;
    right: auto;
    max-height: calc(100% - 80px);
    padding: 10px 10px 0px 10px;
    background: rgba(28, 28, 28, 1);
    border-radius: 15px 15px 0 0;
    box-sizing: border-box;
    animation-name: showList;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    /* animation-delay: 0.5s; */
    /* margin-bottom: -100%; */
    animation-timing-function: ease-out;
    z-index: 50;
  }

  .containerHide{
    animation-name: hideList;
    animation-duration: 0.4s;
  }

  @keyframes showList {
    0% {
      margin-bottom: -100vh;
    }
    100% {
      margin-bottom: 0;
    }
  }

  @keyframes hideList {
    0% {
      margin-bottom: 0;
    }
    100% {
      margin-bottom: -100vh;
    }
  }

  .search {
    border-radius: 35px;
    width: 100%;
  }

  .filters {
    width: 100%;
    margin-bottom: 10px;
    min-height: 43px;
    max-height: 43px;
    font-size: 14px;
    line-height: 16.8px;
  }

  .filters > .filterItem {
    font-size: 14px;
    border-width: 3px;
    padding-bottom: 11px;
    padding-top: 9px;
    border-radius: 20px;
  }

  .filterItemSelected {
    font-size: 14px;
    border-width: 3px;
    padding-bottom: 11px;
    padding-top: 9px;
    border-radius: 20px;
  }

  .hide {
    width: 100%;
    height: fit-content;
    margin-right: auto;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
  }

  .hide > img {
    width: 15px;
    height: 15px;
  }

  .openList {
    position: absolute;
    z-index: 100;
    bottom: 20px;
    left: auto;
    margin-left: auto;
    margin-right: auto;
    background: rgba(28, 28, 28, 1);
    border-radius: 35px;
    box-sizing: border-box;
    padding: 12px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .openList > div {
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 600;
    font-family: "KyivSans";
    font-style: bold;
    color: white;
    margin-left: 10px;
  }

  .list {
    background: transparent;
    padding: 0px;
  }

  .item {
    width: 100%;
  }

  .item > img {
    width: 100%;
    aspect-ratio: 13/7;
    height: auto;
    max-height: 270px;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    object-position: 0px -10px;
  }

  .item > .itemInfo {
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
  }

  .blurDiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 50;
    overflow: hidden;
  }

  .fullDescription {
    position: relative;
    display: flex;
    flex-direction: column;
    /* min-height: 100%; */
    height: fit-content;
    border-radius: 15px 15px 0 0;
    background: rgba(37, 37, 37, 1);
    max-height: none;
  }

  .fullDescription > .content {
    position: relative;
    overflow-y: hidden;
  }

  .navBar {
    height: fit-content;
    width: 100%;
    min-width: 20px;
    position: absolute;
    bottom: 5px;
    left: 0px;
    box-sizing: border-box;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .navBar > .dot,
  .dotActive {
    height: 7.7px;
    min-height: 7.7px;
    width: 7.7px;
    margin-left: 4px;
    border-radius: 50%;
  }

  .navBar > .dot {
    background: rgba(0, 0, 0, 0.6);
  }

  .navBar > .dotActive {
    background: rgb(208, 208, 208);
    height: 8px;
    min-height: 8px;
    width: 8px;
  }

  .fullDescription > .test {
    position: relative;
  }

  .fullDescription > .test > .imgDiv {
    max-height: 800px;
    width: calc(100vw - 40px);
    max-width: 400px;
    aspect-ratio: 7/4;
    border-radius: 15px 15px 0px 0px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-flow: nowrap;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
  }

  .fullDescription > .test > .imgDiv::-webkit-scrollbar {
    /* color: transparent; */
    display: none;
  }

  .imgDiv > .photo {
    min-width: 400px;
    min-height: auto;
    max-height: 300px;
    border-radius: 0px;
    object-fit: cover;
    max-width: 400px;
    width: calc(100vw - 40px);
    scroll-snap-stop: always;
    scroll-snap-align: center;
  }

  .fullDescription > .test > .back {
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 2;
  }
}

@media (max-width: 440px) {
  .imgDiv > .photo {
    min-width: calc(100vw - 40px);
  }

  .fullDescription > .test > .imgDiv {
    max-width: calc(100vw - 40px);
  }
}
