.pointLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;
  width: 650px;
}

.variant {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 19px;
  font-weight: 450;
}

.variant > .text {
  margin-bottom: 4px;
  margin-left: 20px;
}

.point {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
}

.point > .selected {
  width: 18px;
  height: 18px;
  background: #c4c4c4;
  border-radius: 50%;
}

.btnLine{
  width: 650px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 125px;
}

.btnLine > a{
  color: #B8B8B8;
  font-size: 20px;
  font-weight: 450;
  text-underline-position: under;
}

.send,
.sendDisabled {
  padding: 16px 55px 22px;
  border-radius: 30px;
  color: white;
  font-weight: 700;
  font-size: 20px;
  width: fit-content;
  box-sizing: border-box;
}

.sendDisabled {
  background: rgba(70, 70, 70, 1);
}

.send {
  cursor: pointer;
  background: #bd0000;
}

.send:active {
  background: #790000;
}

@media (max-width: 690px) {
  .pointLine {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 15px;
    width: auto;
    flex-wrap: wrap;
    max-width: 300px;
  }

  .btnLine{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 60px;
    width: 100%;
    max-width: 300px;
  }
  
  .btnLine > a{
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
  }

  .send,
  .sendDisabled {
    text-align: center;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    padding: 12px 0 17px;
  }

  .point {
    width: 20px;
    height: 20px;
    border-width: 1.5px;
  }

  .point > .selected {
    width: 10px;
    height: 10px;
  }

  .variant {
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .variant > .text {
    margin-left: 12px;
    font-size: 16px;
  }
}
