.container {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 50px 45px;
  background: linear-gradient(
    238.17deg,
    rgba(22, 22, 22, 0.56) 6.26%,
    rgba(22, 22, 22, 0) 90.39%
  );
  backdrop-filter: blur(6px);
  border-radius: 80px;
  border: 4px solid rgba(236, 236, 236, 0.1);
}

.picked {
  font-size: 37px;
  color: rgba(200, 200, 200, 1);
  font-weight: 200;
  margin-bottom: 12px;
}

.numLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 36px;
}

.needSmall {
  display: none;
}

.current {
  font-size: 70px;
  color: rgba(154, 190, 87, 1);
  font-weight: 700;
  font-family: "Kyiv";
}

.need {
  color: rgba(200, 200, 200, 1);
  text-align: right;
  font-size: 46px;
  font-weight: 200;
}

.progressLine {
  min-width: 100%;
  max-width: 100%;
  position: relative;
  min-height: 22px;
  max-height: 22px;
  background: rgba(38, 38, 38, 1);
  border-radius: 30px;
  overflow: hidden;
}

.progressLine > .done {
  position: absolute;
  top: 0px;
  left: 0px;
  min-height: inherit;
  max-height: inherit;
  border-radius: inherit;
  background: rgba(154, 190, 87, 1);
  box-shadow: -0.952132px -0.952132px 9.52132px rgba(154, 190, 87, 0.5),
    0.952132px 0.952132px 9.52132px rgba(154, 190, 87, 0.5);
  /* animation-name: greater;
  animation-timing-function: ease-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards; */
}

.info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 67px 0 0;
  color: white;
}

.info > .desc {
  display: flex;
  flex-direction: column;
}

.info > .desc > .name {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 8px;
}

.info > .desc > .address {
  font-size: 17px;
  color: #adadad;
  margin-bottom: 8px;
  font-weight: 450;
}

.info > .desc > .link {
  font-size: 17px;
  font-weight: 500;
  color: #2566af;
  margin-bottom: 50px;
}

.info > .desc > .text {
  font-size: 23px;
  font-weight: 400;
}

.info > .textSmall {
  display: none;
}

.info > .photoBlock {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: 60px;
}

.info > .photoBlock > .main {
  width: inherit;
  height: 220px;
  margin-bottom: 23px;
  border-radius: 35px;
  cursor: pointer;
  object-fit: cover;
}

.info > .photoBlock > .list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: inherit;
}

.info > .photoBlock > .list > .photo {
  width: 115px;
  min-width: 115px;
  height: 105px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  background-size: 100% 100%;
}

.photo > .more {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 3px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  font-size: 35px;
  line-height: 170%;
  font-weight: 450;
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: inherit;
  border-radius: inherit;
  height: inherit;
  width: inherit;
  cursor: pointer;
}

.open {
  align-self: center;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(167, 167, 167, 1);
  font-size: 30px;
  font-weight: 450;
  cursor: pointer;
  margin-top: 50px;
}

.open > img {
  margin-top: 30px;
}

.description {
  margin-top: 70px;
  font-size: 25px;
  line-height: 35px;
  color: rgba(200, 200, 200, 1);
  font-weight: 400;
  overflow: hidden;
}

.description > .subTitle {
  font-weight: 700;
  margin-top: 1em;
}

@media (max-width: 690px) {
  .container {
    margin-top: 130px;
    padding: 0;
    background: none;
    backdrop-filter: none;
    border-radius: 0;
    border: none;
  }

  .picked {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .current {
    font-size: 39px;
  }

  .needSmall {
    width: 100%;
    text-align: right;
    font-size: 17px;
    margin-top: 15px;
    color: rgba(200, 200, 200, 1);
    font-weight: 200;
    display: block;
  }

  .need {
    display: none;
  }

  .pickedContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 120px;
  }

  .numLine {
    margin-bottom: 20px;
  }

  .progressLine {
    min-height: 10px;
    max-height: 10px;
  }

  .info {
    flex-direction: column;
    justify-content: flex-start;
    margin: 50px 0 0;
  }

  .info > .photoBlock {
    width: 100%;
    max-width: 400px;
    margin-left: 0;
  }

  .info > .photoBlock > .main {
    width: inherit;
    height: 200px;
    margin-bottom: 16px;
    border-radius: 25px;
  }

  .info > .photoBlock > .list > .photo {
    width: 100px;
    min-width: 100px;
    height: 95px;
    border-radius: 5px;
  }

  .photo > .more {
    font-size: 25px;
  }

  .info > .textSmall {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    display: block;
    margin-top: 30px;
  }

  .info > .desc > .text {
    display: none;
  }

  .info > .desc > .name {
    font-size: 25px;
    margin-bottom: 10px;
  }
  
  .info > .desc > .address {
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .info > .desc > .link {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .open {
    padding: 0 20px;
    font-size: 14px;
    margin-top: 30px;
  }
  
  .open > img {
    margin-top: 15px;
    width: 15px;
    height: auto;
  }

  .description {
    margin-top: 30px;
    font-size: 14px;
    line-height: 160%;
  }
  
  .description > .subTitle {
    font-weight: 700;
    margin-top: 1em;
  }

  .description > ul {
    margin-left: -20px;
  }
}
