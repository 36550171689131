.headerContainer {
  position: fixed;
  width: 382px;
  top: 35px;
  left: calc(50vw - 191px);
  background: #1c1c1c;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  z-index: 50;
}

.langChange {
  position: fixed;
  top: 50px;
  left: 35px;
  cursor: pointer;
  width: 26px;
  height: 18px;
  z-index: 50;
}

.header {
  height: 47px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  box-sizing: border-box;
  padding: 0px 25px;
}

@keyframes dropDown {
  0% {
    height: 47px;
  }
  100% {
    height: auto;
  }
}

.menu {
  margin-bottom: 3px;
  font-weight: 500;
  font-family: 'KyivSans';
  font-size: 20px;
  color: white;
  text-align: left;
  cursor: pointer;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo > img {
  height: 47px;
  transform: scale(1.14);
}

.logoMain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logoMain > img {
  height: 47px;
}

.donate {
  text-align: right;
  color: rgba(154, 190, 87, 1);
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: 600;
  /* text-shadow: 1px 1px 10px rgba(154, 190, 87, 0.5); */
  text-decoration: none;
  cursor: pointer;
  font-family: 'KyivSans';
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  0% {
    text-shadow: 0px 0px 0px rgba(154, 190, 87, 0.5);
  }
  50% {
    text-shadow: 1px 1px 10px rgba(154, 190, 87, 0.5);
  }
  100% {
    text-shadow: 0px 0px 0px rgba(154, 190, 87, 0.5);
  }
}

.linkContainer {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-bottom: 14px;
}

.link {
  font-family: 'KyivSans';
  font-weight: 500;
  font-size: 20px;
  color: #b8b8b8;
  text-decoration: none;
  margin-bottom: 25px;
}

.flag {
  display: none;
}

@media (max-width: 1180px) {
  .headerContainer {
    left: 96px;
  }
}

@media (max-width: 1100px) {
  .headerContainer {
    left: 20px;
    top: 20px;
    width: 350px;
    border-radius: 20px;
  }

  .langChange {
    display: none;
  }

  .header {
    height: 40px;
  }

  .logo > img {
    height: 40px;
  }

  .flag {
    width: 26px;
    margin-top: 7px;
    height: auto;
    cursor: pointer;
    display: block;
  }
}

@media (max-width: 690px) {
  .headerContainer {
    max-width: 400px;
    width: calc(100vw - 40px);
    left: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .header {
    width: 100%;
    padding: 0px 20px;
  }

  .menu {
    margin-bottom: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hr1,
  .hr2 {
    width: 22px;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0px;
  }

  .hr1 {
    margin-bottom: 7px;
  }

  .mapOpened,
  .mapHiding {
    position: fixed;
    /* z-index: 100px; */
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 20px 25px;
    background: rgba(28, 28, 28, 1);
    z-index: 150;
    display: flex;
    flex-direction: column;
    background-image: url('../../res/img/menuBack.webp');
    overflow-y: auto;
    background-size: 100% 100%;
    animation-name: appear;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .mapHiding {
    animation-name: menuHide;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes menuHide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .close {
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .close > .Hr1,
  .Hr2 {
    width: 22px;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0px;
    position: absolute;
    left: 0px;
  }

  .close > .Hr1 {
    /* transform: rotate(45deg); */
    animation-name: rotate1;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    /* margin-bottom: 7px; */
  }

  @keyframes rotate1 {
    0% {
      transform: rotate(0deg);
      margin-bottom: 7px;
    }
    100% {
      transform: rotate(45deg);
      margin-bottom: 0px;
    }
  }

  .close > .Hr2 {
    /* transform: rotate(-45deg); */
    animation-name: rotate2;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  @keyframes rotate2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-45deg);
    }
  }

  .line > .flag {
    width: 32px;
    height: auto;
  }

  .linkContainer {
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .link {
    margin-bottom: 20px;
    margin-top: 0px;
    font-family: 'KyivSans';
    font-weight: 600;
    font-size: 25px;
    color: white;
    text-decoration: none;
    width: fit-content;
  }

  .donateLink {
    color: #9abe57;
    text-shadow: 1px 1px 10px rgba(154, 190, 87, 0.5);
    border-bottom: 4px solid rgba(154, 190, 87, 1);
    padding-bottom: 2px;
    margin-top: 12px;
  }

  .donate {
    font-size: 14px;
  }

  .contacts {
    position: absolute;
    bottom: 25px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
  }
}

@media (max-height: 480px) {
  .contacts {
    position: unset;
    bottom: auto;
    left: auto;
    margin-top: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
