.picked {
  font-size: 40px;
  color: rgba(200, 200, 200, 1);
  font-weight: 200;
}

.pickedContainer{
  margin-top: 180px;
  margin-bottom: 13px;
}

.cont{
  margin-bottom: 180px;
}

.main{
  background-image: url('../../res/img/donateBack.webp');
  background-size: 100% 4500px;
  background-repeat: no-repeat;
}

.numLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 40px;
}

.needSmall {
  display: none;
}

.current {
  font-size: 76px;
  color: rgba(154, 190, 87, 1);
  font-weight: 700;
  font-family: "Kyiv";
}

.need {
  color: rgba(200, 200, 200, 1);
  text-align: right;
  font-size: 50px;
  font-weight: 200;
}

.progressLine {
  min-width: 100%;
  max-width: 100%;
  position: relative;
  min-height: 22px;
  max-height: 22px;
  background: rgba(38, 38, 38, 1);
  border-radius: 30px;
  overflow: hidden;
}

.progressLine > .done {
  position: absolute;
  top: 0px;
  left: 0px;
  min-height: inherit;
  max-height: inherit;
  border-radius: inherit;
  background: rgba(154, 190, 87, 1);
  box-shadow: -0.952132px -0.952132px 9.52132px rgba(154, 190, 87, 0.5),
    0.952132px 0.952132px 9.52132px rgba(154, 190, 87, 0.5);
  /* animation-name: greater;
  animation-timing-function: ease-out;
  animation-duration: 0.6s;
  animation-fill-mode: forwards; */
}

.date{
  margin-top: 30px;
  font-size: 26px;
  color: rgba(109, 109, 109, 1);
  font-weight: 200;
  margin-bottom: -20px;
}

.dateSmall{
  display: none;
}

/* @keyframes greater {
  0%{
    min-width: 0;
  }
  100%{
    width: auto;
  }
} */

.text, .goalText{
  margin-top: 50px;
  width: 100%;
  font-size: 25px;
  line-height: 35px;
  color: white;
  font-weight: 450;
}

.goalText{
  width: 75%;
}

.title {
  margin-top: 140px;
  font-weight: 700;
  font-family: "Kyiv";
  color: white;
  font-size: 76px;
}

.goalsTitle{
  padding-top: 140px;
  margin-top: 0px !important;
}

.card{
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 400px;
  justify-content: center;
  position: relative;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 0px 105px 4px 210px;
  background: rgba(31, 31, 31, 0.56);
  /* backdrop-filter: blur(4px); */
  font-size: 31px;
  line-height: 43px;
  font-weight: 450;
  color: white;
  margin-top: 105px;
}

.num1, .num2, .num3, .num4{
  position: absolute;
  top: -25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 450px;
  line-height: 378px;
  color: rgba(255, 255, 255, 0.03);
  font-weight: 750;
}

.num1{
  left: 73px;
}

.num2, .num4{
  left: 26px;
}

.num3{
  left: 37px;
}

@media (max-width: 690px) {
  .picked {
    font-size: 20px;
  }

  .current {
    font-size: 39px;
  }

  .needSmall {
    width: 100%;
    text-align: right;
    font-size: 17px;
    margin-top: 11px;
    color: rgba(200, 200, 200, 1);
    font-weight: 200;
    display: block;
  }

  .need {
    display: none;
  }

  .dateSmall{
    display: block;
    font-size: 13px;
    font-weight: 200;
    color: rgba(133, 133, 133, 1);
  }

  .pickedContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 120px;
  }

  .date{
    display: none;
  }

  .numLine{
    margin-bottom: 18px;
  }

  .progressLine{
    min-height: 10px;
    max-height: 10px;
  }

  .text, .goalText{
    margin-top: 30px;
    width: 100%;
    font-size: 16px;
    line-height: 26px;
  }

  .goalsTitle{
    padding-top: 30px;
  }

  .title {
    margin-top: 80px;
    font-weight: 700;
    font-family: "Kyiv";
    color: white;
    font-size: 44px;
  }

  .main{
    background-image: url('../../res/img/smallDonateBack.webp');
    background-size: 100% 3100px;
  }

  .card{
    height: 210px;
    border-radius: 25px;
    padding: 0px 19px 2px 53px;
    font-size: 18px;
    line-height: 25px;
    margin-top: 40px;
  }

  .num1, .num2, .num3, .num4{
    top: -10px;
    height: 100%;
    font-size: 235px;
    line-height: 198px;
  }
  
  .num1{
    left: 5px;
  }
  
  .num2, .num4{
    left: -21px;
  }
  
  .num3{
    left: -15px;
  }
}
