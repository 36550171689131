.container {
  display: flex;
  flex-direction: column;
  width: 840px;
  padding-top: 80px;
  /* height: 650px;
  min-height: 650px; */
  height: fit-content;
}

.variants {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 38px;
}

.variant,
.variantSelected {
  font-size: 20px;
  /* padding: 15px 39px 17px; */
  padding: 0 0 4px;
  color: #eeeeee;
  font-size: 20px;
  line-height: 140%;
  border: 2px solid #c4c4c4;
  border-radius: 999px;
  font-weight: 450;
  cursor: pointer;
  height: 58px;
  width: 180px;
  transition: background-color 0.2s linear;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.variantSelected {
  color: rgba(29, 29, 29, 1);
  background-color: rgba(196, 196, 196, 1);
}

.variant:hover {
  font-weight: 700;
}

.variantLine,
.currencyLine {
  height: 1px;
  border-radius: 50px;
  width: 840px;
  min-height: 1px;
  position: relative;
  background: rgba(215, 215, 215, 0.8);
}

.variantLineSelected {
  position: absolute;
  top: -1px;
  height: 3px;
  background: rgba(255, 255, 255, 0.8);
  transition: left 0.25s ease-out, width 0.25s ease-out;
  width: 25%;
  /* transition: width 0.1s ease-out; */
}

.currencies {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  font-size: 20px;
  color: #eeeeee;
  margin-bottom: 27px;
  cursor: pointer;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.currencies > .currency,
.currencySelected {
  font-size: 20px;
  font-weight: 450;
  color: #eeeeee;
}

.currencies > .currencySelected {
  font-weight: 700;
}

.currencyLine {
  margin-bottom: 65px;
}

.requiseBlock {
  animation-name: appear;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.currencyLineSelected {
  position: absolute;
  top: -1px;
  height: 3px;
  background: rgba(255, 255, 255, 0.8);
  width: 33.3333333%;
  border-radius: 30px;
  transition: left 0.25s ease-out;
}

.currencyDesc {
  font-size: 24px;
  line-height: 40px;
  color: #eeeeee;
  /* animation-name: appear;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards; */
  transition: content 0.25s ease-out;
}

.liqContainer{
  width: 100%;
  margin-top: 45px;
  box-sizing: border-box;
  display: grid;
  /* flex-direction: row; */
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 34px;
  align-items: center;
  padding: 0px 10px;
  animation-name: appear;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.paymentLine{
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 450px;
  max-width: 100%;
  height: auto;
}

.liqContainer > .liqLink{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 14px 0 16px;
  border-radius: 100px;
  cursor: pointer;
  border: 2px solid rgba(196, 196, 196, 1);
  width: 100%;
  font-size: 20px;
  font-weight: 450;
  color: rgba(196, 196, 196, 1);
  text-decoration: none;
  transition: background-color 0.2s linear;
}

.liqContainer > .liqLink:hover{
  font-weight: 700;
}

.liqContainer > .liqLink:active{
  color: rgba(29, 29, 29, 1);
  background-color: rgba(196, 196, 196, 1);
}

.cryptContainer {
  width: 100%;
  margin-top: 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  animation-name: appear;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.crypt {
  width: 100%;
  height: 67px;
  border-radius: 99px;
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 39px;
  font-size: 20px;
  line-height: 28px;
  color: white;
  font-weight: 450;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.2s ease-out;
  overflow: hidden;
  transition: color 0.1s ease-out;
  /* backdrop-filter: blur(100px);
  background: transparent; */
}

.crypt:hover {
  border-color: #9abe57;
}

.crypt > .copy {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 60%;
  background: linear-gradient(
    270deg,
    #9abe57 50.79%,
    rgba(161, 200, 89, 0.5) 71.96%,
    rgba(161, 200, 89, 0) 98.04%
  );
  border-radius: 0px 99px 99px 0px;
  text-align: center;
  box-sizing: border-box;
  padding-left: 320px;
  padding-right: 40px;
  height: inherit;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2px;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.2s ease-out;
  z-index: 0;
  text-align: right;
  color: white !important;
}

.crypt:active {
  color: #9abe57;
}

.crypt:hover > .copy {
  transform: scaleX(1);
}

.crypt > .name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.crypt > .name > div {
  margin-left: 16px;
  margin-bottom: 2px;
}

.crypt > .name > .imgDiv {
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  width: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.crypt > .name > .imgDiv > img {
  height: 30px;
  width: auto;
}

.crypt > .link {
  margin-bottom: 2px;
  max-width: 430px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.paypal {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  font-size: 36px;
  font-weight: 450;
}

.paypal > .copyEmail {
  cursor: pointer;
  transition: color 0.2s ease-out;
}

.paypal > .copiedEmail {
  cursor: pointer;
  color: #627d30;
}

.paypal > .copyEmail:hover {
  color: #7fa23f;
}

@media (max-width: 690px) {
  .container {
    width: 100%;
    padding-top: 60px;
    max-width: 450px;
    /* height: 580px;
    min-height: 580px; */
  }

  .variants {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 0 0;
    border: 1px solid #ffffff;
    border-radius: 28px;
  }

  .variant,
  .variantSelected {
    font-size: 14px;
    padding: 0 0 3px 0;
    border: none;
    border-radius: 28px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
  }

  .variantSelected {
    color: rgba(29, 29, 29, 1);
    background: white;
  }

  .variant:hover {
    font-weight: 700;
  }

  .variantLine {
    display: none;
  }

  .currencyLine {
    width: 100%;
    margin-bottom: 35px;
  }

  .currencies {
    margin-top: 35px;
    width: 100%;
    margin-bottom: 16px;
  }

  .currencyDesc {
    font-size: 16px;
    line-height: 27px;
  }

  .paymentLine{
    margin-top: 13px;
    width: 265px;
  }

  .liqContainer{
    margin-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
  }
  
  .liqContainer > .liqLink{
    font-size: 14px;
    margin-bottom: 13px;
  }

  .liqContainer > .liqLink:hover{
    font-weight: 450;
  }

  .cryptContainer {
    width: 100%;
    margin-top: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
  }

  .crypt {
    height: 50px;
    border: 1px solid #c4c4c4;
    padding: 0px 9px;
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .crypt:hover {
    border-color: #c4c4c4;
  }

  .crypt:hover > .copy {
    display: flex;
  }

  .crypt > .name > div {
    margin-left: 6px;
    margin-bottom: 0px;
  }

  .crypt > .name > .imgDiv {
    width: 22px;
  }

  .crypt > .name > .imgDiv > img {
    height: 20px;
  }

  .crypt > .link {
    margin-bottom: 2px;
    max-width: 45%;
  }

  .crypt > .copy {
    padding-left: 80px;
    animation-name: hideCryptCopy;
    animation-delay: 1.5s;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    transform: scaleX(1);
  }

  @keyframes hideCryptCopy {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .paypal {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
    font-weight: 450;
  }

  .paypal > .copyEmail {
    cursor: pointer;
    font-size: 16;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 16px;
    border-radius: 300px;
    border: 1px solid rgba(196, 196, 196, 1);
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
    transition: background-color 0.2s ease-out;
    transition: border-color 0.2s ease-out;
  }

  .paypal > .copiedEmail {
    cursor: pointer;
    font-size: 16;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 16px;
    border-radius: 300px;
    border: 1px solid #627d30;
    box-sizing: border-box;
    margin-top: 20px;
    width: 100%;
    background-color: #627d30;
    color: white;
  }

  .paypal > .copyEmail:hover {
    color: white;
  }
}
